import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrashAlt } from "react-icons/fa";

import EditIcon from "../../assets/svgs/pencil.inline.svg";
import requests from "../../services/requests";
import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Paginate from "../../components/Paginate/Paginate";
import ModalRemove from "./components/ModalRemove";
import ModalEdit from "./components/ModalEdit";
import ModalRegister from "./components/ModalRegister";

const AditionalFields = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [tabModal, setTabModal] = useState("Cadastro de campo adicional");
    const [tabModalEdit, setTabModalEdit] = useState("Edição de campo adicional");
    const [tabModalRemove, setTabModalRemove] = useState("Remover campo adicional");
    const [list, setList] = useState([]);
    const [currentEntity, setCurrentEntity] = useState(null);
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    useEffect(() => {
        loadList();
    }, [action]);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const loadList = async () => {
        requests.listAditionalFields({}, meta.current_page > 0 ? meta.current_page : 1, source.token).then(list => {
            setList(list.data);
            setMeta(list.meta);
        });
        source.cancel("Operation canceled by the user.");
    };

    const actionModal = () => {
        setOpenModal(!openModal);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const actionModalEdit = user => {
        setCurrentEntity(user);
        setOpenModalEdit(!openModalEdit);
    };

    const actionModalRemove = user => {
        setCurrentEntity(user);
        setOpenModalRemove(!openModalRemove);
    };

    const closeModalEdit = () => {
        setOpenModalEdit(false);
    };

    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    return (
        <Layout>
            <ModalRegister
                actionModal={closeModal}
                tabModal={tabModal}
                setTabModal={setTabModal}
                openModal={openModal}
                handleSuccess={() => {
                    closeModal();
                    loadList();
                }}
            />
            <ModalEdit
                actionModal={closeModalEdit}
                tabModal={tabModalEdit}
                setTabModal={setTabModalEdit}
                openModal={openModalEdit}
                selectedEntity={currentEntity}
                loadList={loadList}
            />
            <ModalRemove
                actionModal={closeModalRemove}
                tabModal={tabModalRemove}
                setTabModal={setTabModalRemove}
                openModal={openModalRemove}
                entity={currentEntity}
                handleSuccess={() => {
                    closeModalRemove();
                    loadList();
                }}
            />
            <SEO title="Gestão de campos adicionais" />
            <div className="p-4">
                <Card>
                    <div className="flex justify-between border-b border-C2 pb-4">
                        <button className="bg-roxo_oficial text-white px-4 py-1 rounded-md" onClick={actionModal}>
                            Cadastrar campo adicional
                        </button>
                    </div>
                    <div className="flex justify-between my-4">
                        <h5 className="uppercase text-C6">Campos adicionais</h5>
                    </div>

                    <table className="table-auto w-full">
                        <thead className="border-b border-roxo_oficial">
                            <tr>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Título do campo</th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Data de cadastro</th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">Data de última alteração</th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">
                                    Responsável pelo cadastro
                                </th>
                                <th className="text-left min-w-5 text-roxo_oficial font-normal">
                                    Última pessoa que alterou
                                </th>
                                <th className="w-16 text-center text-roxo_oficial font-normal">Editar</th>
                                <th className="w-16 text-center text-roxo_oficial font-normal">Excluir</th>
                            </tr>
                        </thead>
                        <tbody className="pt-2">
                            {list.map((data, key) => {
                                return (
                                    <tr key={data.identifier}>
                                        <td className="text-left font-normal">{data.label}</td>
                                        <td className="text-left font-normal">
                                            {data.createdAtFormatted}
                                        </td>
                                        <td className="text-left font-normal">
                                            {data.updatedAtFormatted}
                                        </td>
                                        <td className="text-left font-normal">{data.createdBy.name}</td>
                                        <td className="text-left font-normal">{data.lastUpdatedBy.name}</td>
                                        <td className="w-16 text-center font-normal">
                                            <button
                                                className="p-2 mt-2 rounded-md bg-roxo_oficial_light"
                                                onClick={() => actionModalEdit(data)}
                                                title="Editar campo adicional"
                                            >
                                                <EditIcon />
                                            </button>
                                        </td>
                                        <td className="w-16 text-center font-normal">
                                            <button
                                                className="p-2  mt-2 rounded-md bg-canal_vermelho"
                                                onClick={() => actionModalRemove(data)}
                                                title="Remover campo adicional"
                                            >
                                                <FaTrashAlt color="#fff" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                    )}
                </Card>
            </div>
        </Layout>
    );
};

export default AditionalFields;
