import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { Formik, ErrorMessage } from "formik";

import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import requests from "../../../services/requests";

const yup = require("yup");

const ModalEdit = ({ actionModal, openModal, selectedEntity, loadList }) => {
    let [load, setLoad] = useState(false);
    let [entity, setEntity] = useState(null);

    useEffect(() => {
        setLoad(true);
        if (selectedEntity && selectedEntity.identifier) {
            requests.showAditionalField(selectedEntity.identifier).then(data => {
                setEntity(data);
                setLoad(false);
            });
        }
    }, [selectedEntity]);

    return (
        <Modal onClose={actionModal} size="xs:w-11/12 lg:w-6/12" show={openModal} title={<h5>Edição de campo adicional</h5>}>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <>
                    {entity && (
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                label: entity.label,
                            }}
                            validationSchema={yup.object().shape({
                                label: yup.string().required("Campo obrigatório"),
                            })}
                            onSubmit={(values, { setFieldError, setSubmitting }) => {
                                setSubmitting(true);
                                requests
                                    .editAditionalField(selectedEntity.identifier, values)
                                    .then(() => {
                                        setSubmitting(false);
                                        loadList();
                                        toast.success("Informações alteradas com sucesso!");
                                    })
                                    .catch(error => {
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                errors,
                                values,
                                touched,
                                handleBlur,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <label htmlFor="label" className="text-roxo_oficial font-bold">
                                            Título do campo
                                        </label>
                                        <Input name="label" id="label" onChange={handleChange} value={values.label} />
                                        <ErrorMessage
                                            component="label"
                                            name="label"
                                            className="text-red font-light w-full"
                                        />
                                    </div>
                                    <div className="flex justify-end mt-10">
                                        <button
                                            className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? (
                                                <>
                                                    Salvando <ClipLoader size={10} loading={isSubmitting} />
                                                </>
                                            ) : (
                                                <>Salvar</>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    )}
                </>
            )}
        </Modal>
    );
};
export default ModalEdit;
